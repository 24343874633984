import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {useInstitution} from '../../../../../app/modules/auth/core/InstitutionContext'
import {getAuth, hasPermission} from '../../../../../app/modules/auth/core/AuthHelpers'

export function MenuInner() {
  const intl = useIntl()
  const {institutions, selectedInstitution, setSelectedInstitution, setInstitutions} =
    useInstitution()

  const auth = getAuth()
  const userRoles = auth?.data?.user_roles

  const isAdmin = userRoles?.some((role) => role === 'admin')

  return (
    <>
      {/* <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' /> */}
      <MenuItem fontIcon='bi-house-door' title='Home' to='/dashboard' />

      {hasPermission('strategicDirection-read') && (
        <MenuInnerWithSub
          title='Planificación Estratégica Institucional'
          to='#'
          fontIcon='bi-puzzle-fill'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          <MenuInnerWithSub
            title='Planificación'
            to='#'
            fontIcon='bi-calendar-event'
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {hasPermission('strategicDirection-read') && (
              <MenuItem
                fontIcon='bi-compass-fill'
                to='pei/planificacion/direccionamiento-estrategico'
                title='Direccionamiento Estratégico'
              />
            )}

            {hasPermission('objectiveProgram-read') && (
              <MenuItem
                fontIcon='bi-bullseye'
                to='pei/planificacion/objetivos-programa'
                title='Objetivos Estratégicos del Programa'
              />
            )}

            {hasPermission('linkedProgram-read') && (
              <MenuItem
                fontIcon='bi-link'
                to='/pei/planificacion/direccionamiento-estrategico/programas-vinculados'
                title='Programas vinculados'
              />
            )}

            {hasPermission('countryVision-read') && (
              <MenuItem
                fontIcon='bi-flag-fill'
                to='/pei/planificacion/direccionamiento-estrategico/vision-pais'
                title='Visión País'
              />
            )}
            {hasPermission('LinkageSustainableDevelopmentGoal-write') && (
              <MenuItem
                fontIcon='bi-recycle'
                to='/pei/planificacion/direccionamiento-estrategico/objetivos-desarrollo-sostenible'
                title='Agenda Nacional 2030 de los ODS'
              />
            )}
            {hasPermission('LinkageNationalDevelopmentPlan-write') && (
              <MenuItem
                fontIcon='bi-building'
                to='/pei/planificacion/direccionamiento-estrategico/vinculacion-plan-estrategico-gobierno'
                title='Plan Estratégico de Gobierno'
              />
            )}
            {hasPermission('TransversalAxis-write') && (
              <MenuItem
                fontIcon='bi-arrow-down-up'
                to='/pei/planificacion/direccionamiento-estrategico/ejes-transversales'
                title='Ejes Transversales'
              />
            )}
          </MenuInnerWithSub>

          <MenuInnerWithSub
            title='Seguimiento'
            to='#'
            fontIcon='bi-card-checklist'
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem
              fontIcon='bi-table'
              to='pei/seguimiento/resumen-vinculacion'
              title='Resumen de Vinculación'
            />

            <MenuItem
              fontIcon='bi-list'
              to='pei/seguimiento/indicadores-estrategicos'
              title='Indicadores Estratégicos'
            />
            {hasPermission('finalProductIndicator-read') && (
              <MenuItem
                fontIcon='bi-archive-fill'
                to='pei/seguimiento/productos-finales'
                title='Productos Finales e Indicadores'
              />
            )}

            {hasPermission('performanceIndicators-read') && (
              <MenuItem
                fontIcon='bi-bar-chart-fill'
                to='pei/seguimiento/indicadores-desempenio'
                title='Indicadores de Desempeño'
              />
            )}
          </MenuInnerWithSub>
        </MenuInnerWithSub>
      )}

      {hasPermission('operationalAnnualPlan-read') && (
        <MenuInnerWithSub
          title='Plan Operativo Anual'
          to='#'
          fontIcon='bi-calendar-check-fill'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          <MenuInnerWithSub
            title='Planificación'
            to='#'
            fontIcon='bi-calendar-event'
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {hasPermission('operationalAnnualPlan-read') && (
              <MenuItem
                fontIcon='bi-pencil-square'
                to='pao/planificacion/plan-anual-operativo'
                title='Ingreso'
              />
            )}
            {hasPermission('operationalAnnualPlan-write') && (
              <MenuItem
                fontIcon='bi-check2-square'
                to='pao/planificacion/plan-anual-operativo-verificacion'
                title='Verificación'
              />
            )}
          </MenuInnerWithSub>

          <MenuInnerWithSub
            title='Seguimiento'
            to='#'
            fontIcon='bi-card-checklist'
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {hasPermission('monitoringOperationalAnnualPlan-read') && (
              <MenuItem
                fontIcon='bi-pencil-square'
                to='pao/planificacion/plan-anual-operativo-seguimiento'
                title='Ingreso'
              />
            )}
            {hasPermission('monitoringOperationalAnnualPlan-write') && (
              <MenuItem
                fontIcon='bi-check2-square'
                to='pao/planificacion/plan-anual-operativo-seguimiento-verificacion'
                title='Verificación'
              />
            )}
          </MenuInnerWithSub>
        </MenuInnerWithSub>
      )}

      {hasPermission('municipalDevelopmentPlanMenu-read') && (
        <MenuInnerWithSub
          title='Plan de Desarrollo Municipal'
          to='/reports'
          fontIcon='bi-map-fill'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          {hasPermission('municipalDevelopmentPlanIngress-read') && (
            <MenuItem
              fontIcon='bi-pencil-square'
              to='plan-desarrollo-municipal/ingreso'
              title='Ingreso'
            />
          )}
          {hasPermission('municipalDevelopmentPlanVerification-read') && (
            <MenuItem
              fontIcon='bi-check2-square'
              to='plan-desarrollo-municipal/verificacion'
              title='Verificación'
            />
          )}
        </MenuInnerWithSub>
      )}

      {hasPermission('reports-read') && (
        <MenuInnerWithSub
          title='Reportes'
          to='/reports'
          fontIcon='bi-file-earmark-post-fill'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          {hasPermission('reports-sinit-read') && (
            <MenuInnerWithSub
              title='SINIT'
              to='#'
              fontIcon='bi-file-earmark-post'
              hasArrow={true}
              menuPlacement='right-start'
              menuTrigger={`{default:'click', lg: 'hover'}`}
            >
              {hasPermission('reports-sinit-pei-read') && (
                <MenuItem
                  fontIcon='bi-calendar-event'
                  to='reportes/sinit/pei'
                  title='Seguimiento PEI'
                />
              )}
              {hasPermission('reports-sinit-poa-read') && (
                <MenuItem
                  fontIcon='bi-calendar-event'
                  to='reportes/sinit/poa'
                  title='Seguimiento POA'
                />
              )}
            </MenuInnerWithSub>
          )}
          {hasPermission('publicPolicies-report') && (
            <MenuItem
              fontIcon='bi-table'
              to='politicas-publicas/report'
              title='Politicas Públicas'
            />
          )}

          {hasPermission('reports-monitoring') && (
            <MenuItem
              fontIcon='bi-box-arrow-up-right'
              to='reportes/sinit/monitoreo'
              title='Reportes de Monitoreo'
            />
          )}
        </MenuInnerWithSub>
      )}
      {hasPermission('publicPolicies-read') && (
        <MenuItem
          fontIcon='bi-house-door'
          title='Políticas Públicas'
          to='politicas-publicas/index'
        />
      )}
      
      {/* {(isAdmin || hasPermission('documentationMenu-read')) && (
        <MenuInnerWithSub
          title='Documentación'
          to='#'
          fontIcon='bi-patch-check-fill'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >

        {(isAdmin || hasPermission('certificatesMenu-read')) && (
          <MenuInnerWithSub
            title='Certificados'
            to='#'
            fontIcon='bi-gear-fill'
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
          </MenuInnerWithSub>
        )}

        {(isAdmin || hasPermission('opinionsMenu-read')) && (
          <MenuInnerWithSub
            title='Dictamenes'
            to='#'
            fontIcon='bi-patch-check-fill'
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {(isAdmin || hasPermission('legalOpinion-read')) && (
              <MenuItem
                fontIcon='bi-file-earmark-arrow-down-fill'
                to='certificados/dictamen-legal'
                title='Dictamen Legal'
              />
            )}
            {(isAdmin || hasPermission('technicalOpinion-read')) && (
              <MenuItem
                fontIcon='bi-file-earmark-arrow-down-fill'
                to='certificados/dictamen-tecnico'
                title='Dictamen Técnico'
              />
            )}
            {(isAdmin || hasPermission('technicalOpinionModification-read')) && (
              <MenuItem
                fontIcon='bi-file-earmark-arrow-down-fill'
                to='certificados/dictamen-tecnico-modificacion'
                title='Dictamen Técnico de Modificación'
              />
            )}
            {(isAdmin || hasPermission('technicalOpinionPOA-read')) && (
              <MenuItem
                fontIcon='bi-file-earmark-arrow-down-fill'
                to='certificados/dictamen-tecnico-poa'
                title='Dictamen Técnico de POA'
              />
            )}
          </MenuInnerWithSub>
        )}
        </MenuInnerWithSub>
      )} */}
      
      {(isAdmin || hasPermission('check-admin-menu')) && (
        <MenuInnerWithSub
          title='Administración'
          to='/administration'
          fontIcon='bi-gear-fill'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          { (isAdmin || hasPermission('check-config-menu')) && (
          <MenuInnerWithSub
            title='Configuración'
            to='/indicadores'
            fontIcon='bi-gear-fill'
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            {(isAdmin || hasPermission('planning-periods-read')) && (
            <MenuItem
              fontIcon='bi-calendar-event-fill'
              to='administration/indicadores/periodos-planificacion'
              title='Periodos de planificacion'
            />)}
            {(isAdmin || hasPermission('institutions-read')) && (
            <MenuItem
              fontIcon='bi-building'
              to='administration/indicadores/instituciones'
              title='Instituciones'
            /> )}
            {(isAdmin || hasPermission('departments-read')) && (
            <MenuItem
              fontIcon='bi-map'
              to='administration/indicadores/departamentos'
              title='Departamentos'
            /> )}
            {(isAdmin || hasPermission('municipalities-read')) && (
            <MenuItem
              fontIcon='bi-map'
              to='administration/indicadores/municipios'
              title='Municipios'
            /> )}

            {(isAdmin || hasPermission('signers-read')) && (
            <MenuItem
              fontIcon='bi-pen'
              to='administration/indicadores/firmantes'
              title='Firmantes'
            /> )}
            {(isAdmin || hasPermission('unit-measurements-read')) && (
            <MenuItem
              fontIcon='bi-rulers'
              to='administration/indicadores/unidades-medida'
              title='Unidades de medida'
            />)}
            {(isAdmin || hasPermission('municipal-development-categories-read')) && (
            <MenuItem
              fontIcon='bi-list'
              to='administration/indicadores/desarrollo-municipal-categorias'
              title='Categorías de Desarrollo Municipal'
            /> )}
            {(isAdmin || hasPermission('currency-read')) && (
            <MenuItem fontIcon='bi-currency-dollar' to='administration/monedas' title='Moneda' />
            )}
            {(isAdmin || hasPermission('financial-read')) && (

            <MenuItem
              fontIcon='bi-bank'
              to='administration/fuentes-financiamiento'
              title='Fuente de Financiamiento'
            /> )}
            {(isAdmin || hasPermission('financialEntity-read')) && (
            <MenuItem
              fontIcon='bi-briefcase'
              to='administration/entidades-financiamiento'
              title='Entidad de Fianciamiento'
            /> )}
            {(isAdmin || hasPermission('cabinets-read')) && (
              <MenuItem fontIcon='bi-people' to='administration/cabinets' title='Gabinetes' />
            )}
            {(isAdmin ||hasPermission('policyTypes-read')) && (
              <MenuItem
                fontIcon='bi-list-ul'
                to='administration/public-policy-type'
                title='Tipos de politica publica'
              />
            )}
            {(isAdmin || hasPermission('policyState-read')) && (
              <MenuItem
                fontIcon='bi-list-ul'
                to='administration/public-policy-state'
                title='Estados de politica publica'
              />
            )}
          </MenuInnerWithSub>
          )}

          { (isAdmin || hasPermission('check-security-menu')) && (


          <MenuInnerWithSub
            title='Seguridad'
            to='/security'
            fontIcon='bi-shield-check'
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem
              fontIcon='bi-person-gear'
              to='administration/security/users'
              title='Usuarios'
            />
            <MenuItem
              fontIcon='bi-bookmark-plus-fill'
              to='administration/security/roles'
              title='Roles'
            />

            <MenuItem fontIcon='bi-key' to='administration/security/permissions' title='Permisos' />
          </MenuInnerWithSub>
          )}
        </MenuInnerWithSub>
      )}
    </>
  )
}
